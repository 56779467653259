export var TimezoneEnum;
(function (TimezoneEnum) {
    TimezoneEnum["AFRICA_ABIDJAN"] = "Africa/Abidjan";
    TimezoneEnum["AFRICA_ACCRA"] = "Africa/Accra";
    TimezoneEnum["AFRICA_ADDIS_ABABA"] = "Africa/Addis_Ababa";
    TimezoneEnum["AFRICA_ALGIERS"] = "Africa/Algiers";
    TimezoneEnum["AFRICA_ASMARA"] = "Africa/Asmara";
    TimezoneEnum["AFRICA_ASMERA"] = "Africa/Asmera";
    TimezoneEnum["AFRICA_BAMAKO"] = "Africa/Bamako";
    TimezoneEnum["AFRICA_BANGUI"] = "Africa/Bangui";
    TimezoneEnum["AFRICA_BANJUL"] = "Africa/Banjul";
    TimezoneEnum["AFRICA_BISSAU"] = "Africa/Bissau";
    TimezoneEnum["AFRICA_BLANTYRE"] = "Africa/Blantyre";
    TimezoneEnum["AFRICA_BRAZZAVILLE"] = "Africa/Brazzaville";
    TimezoneEnum["AFRICA_BUJUMBURA"] = "Africa/Bujumbura";
    TimezoneEnum["AFRICA_CAIRO"] = "Africa/Cairo";
    TimezoneEnum["AFRICA_CASABLANCA"] = "Africa/Casablanca";
    TimezoneEnum["AFRICA_CEUTA"] = "Africa/Ceuta";
    TimezoneEnum["AFRICA_CONAKRY"] = "Africa/Conakry";
    TimezoneEnum["AFRICA_DAKAR"] = "Africa/Dakar";
    TimezoneEnum["AFRICA_DAR_ES_SALAAM"] = "Africa/Dar_es_Salaam";
    TimezoneEnum["AFRICA_DJIBOUTI"] = "Africa/Djibouti";
    TimezoneEnum["AFRICA_DOUALA"] = "Africa/Douala";
    TimezoneEnum["AFRICA_EL_AAIUN"] = "Africa/El_Aaiun";
    TimezoneEnum["AFRICA_FREETOWN"] = "Africa/Freetown";
    TimezoneEnum["AFRICA_GABORONE"] = "Africa/Gaborone";
    TimezoneEnum["AFRICA_HARARE"] = "Africa/Harare";
    TimezoneEnum["AFRICA_JOHANNESBURG"] = "Africa/Johannesburg";
    TimezoneEnum["AFRICA_JUBA"] = "Africa/Juba";
    TimezoneEnum["AFRICA_KAMPALA"] = "Africa/Kampala";
    TimezoneEnum["AFRICA_KHARTOUM"] = "Africa/Khartoum";
    TimezoneEnum["AFRICA_KIGALI"] = "Africa/Kigali";
    TimezoneEnum["AFRICA_KINSHASA"] = "Africa/Kinshasa";
    TimezoneEnum["AFRICA_LAGOS"] = "Africa/Lagos";
    TimezoneEnum["AFRICA_LIBREVILLE"] = "Africa/Libreville";
    TimezoneEnum["AFRICA_LOME"] = "Africa/Lome";
    TimezoneEnum["AFRICA_LUANDA"] = "Africa/Luanda";
    TimezoneEnum["AFRICA_LUBUMBASHI"] = "Africa/Lubumbashi";
    TimezoneEnum["AFRICA_LUSAKA"] = "Africa/Lusaka";
    TimezoneEnum["AFRICA_MALABO"] = "Africa/Malabo";
    TimezoneEnum["AFRICA_MAPUTO"] = "Africa/Maputo";
    TimezoneEnum["AFRICA_MASERU"] = "Africa/Maseru";
    TimezoneEnum["AFRICA_MBABANE"] = "Africa/Mbabane";
    TimezoneEnum["AFRICA_MOGADISHU"] = "Africa/Mogadishu";
    TimezoneEnum["AFRICA_MONROVIA"] = "Africa/Monrovia";
    TimezoneEnum["AFRICA_NAIROBI"] = "Africa/Nairobi";
    TimezoneEnum["AFRICA_NDJAMENA"] = "Africa/Ndjamena";
    TimezoneEnum["AFRICA_NIAMEY"] = "Africa/Niamey";
    TimezoneEnum["AFRICA_NOUAKCHOTT"] = "Africa/Nouakchott";
    TimezoneEnum["AFRICA_OUAGADOUGOU"] = "Africa/Ouagadougou";
    TimezoneEnum["AFRICA_PORTO_NOVO"] = "Africa/Porto-Novo";
    TimezoneEnum["AFRICA_SAO_TOME"] = "Africa/Sao_Tome";
    TimezoneEnum["AFRICA_TIMBUKTU"] = "Africa/Timbuktu";
    TimezoneEnum["AFRICA_TRIPOLI"] = "Africa/Tripoli";
    TimezoneEnum["AFRICA_TUNIS"] = "Africa/Tunis";
    TimezoneEnum["AFRICA_WINDHOEK"] = "Africa/Windhoek";
    TimezoneEnum["AMERICA_ADAK"] = "America/Adak";
    TimezoneEnum["AMERICA_ANCHORAGE"] = "America/Anchorage";
    TimezoneEnum["AMERICA_ANGUILLA"] = "America/Anguilla";
    TimezoneEnum["AMERICA_ANTIGUA"] = "America/Antigua";
    TimezoneEnum["AMERICA_ARAGUAINA"] = "America/Araguaina";
    TimezoneEnum["AMERICA_ARGENTINA_BUENOS_AIRES"] = "America/Argentina/Buenos_Aires";
    TimezoneEnum["AMERICA_ARGENTINA_CATAMARCA"] = "America/Argentina/Catamarca";
    TimezoneEnum["AMERICA_ARGENTINA_COMOD_RIVADAVIA"] = "America/Argentina/ComodRivadavia";
    TimezoneEnum["AMERICA_ARGENTINA_CORDOBA"] = "America/Argentina/Cordoba";
    TimezoneEnum["AMERICA_ARGENTINA_JUJUY"] = "America/Argentina/Jujuy";
    TimezoneEnum["AMERICA_ARGENTINA_LA_RIOJA"] = "America/Argentina/La_Rioja";
    TimezoneEnum["AMERICA_ARGENTINA_MENDOZA"] = "America/Argentina/Mendoza";
    TimezoneEnum["AMERICA_ARGENTINA_RIO_GALLEGOS"] = "America/Argentina/Rio_Gallegos";
    TimezoneEnum["AMERICA_ARGENTINA_SALTA"] = "America/Argentina/Salta";
    TimezoneEnum["AMERICA_ARGENTINA_SAN_JUAN"] = "America/Argentina/San_Juan";
    TimezoneEnum["AMERICA_ARGENTINA_SAN_LUIS"] = "America/Argentina/San_Luis";
    TimezoneEnum["AMERICA_ARGENTINA_TUCUMAN"] = "America/Argentina/Tucuman";
    TimezoneEnum["AMERICA_ARGENTINA_USHUAIA"] = "America/Argentina/Ushuaia";
    TimezoneEnum["AMERICA_ARUBA"] = "America/Aruba";
    TimezoneEnum["AMERICA_ASUNCION"] = "America/Asuncion";
    TimezoneEnum["AMERICA_ATIKOKAN"] = "America/Atikokan";
    TimezoneEnum["AMERICA_ATKA"] = "America/Atka";
    TimezoneEnum["AMERICA_BAHIA"] = "America/Bahia";
    TimezoneEnum["AMERICA_BAHIA_BANDERAS"] = "America/Bahia_Banderas";
    TimezoneEnum["AMERICA_BARBADOS"] = "America/Barbados";
    TimezoneEnum["AMERICA_BELEM"] = "America/Belem";
    TimezoneEnum["AMERICA_BELIZE"] = "America/Belize";
    TimezoneEnum["AMERICA_BLANC_SABLON"] = "America/Blanc-Sablon";
    TimezoneEnum["AMERICA_BOA_VISTA"] = "America/Boa_Vista";
    TimezoneEnum["AMERICA_BOGOTA"] = "America/Bogota";
    TimezoneEnum["AMERICA_BOISE"] = "America/Boise";
    TimezoneEnum["AMERICA_BUENOS_AIRES"] = "America/Buenos_Aires";
    TimezoneEnum["AMERICA_CAMBRIDGE_BAY"] = "America/Cambridge_Bay";
    TimezoneEnum["AMERICA_CAMPO_GRANDE"] = "America/Campo_Grande";
    TimezoneEnum["AMERICA_CANCUN"] = "America/Cancun";
    TimezoneEnum["AMERICA_CARACAS"] = "America/Caracas";
    TimezoneEnum["AMERICA_CATAMARCA"] = "America/Catamarca";
    TimezoneEnum["AMERICA_CAYENNE"] = "America/Cayenne";
    TimezoneEnum["AMERICA_CAYMAN"] = "America/Cayman";
    TimezoneEnum["AMERICA_CHICAGO"] = "America/Chicago";
    TimezoneEnum["AMERICA_CHIHUAHUA"] = "America/Chihuahua";
    TimezoneEnum["AMERICA_CORAL_HARBOUR"] = "America/Coral_Harbour";
    TimezoneEnum["AMERICA_CORDOBA"] = "America/Cordoba";
    TimezoneEnum["AMERICA_COSTA_RICA"] = "America/Costa_Rica";
    TimezoneEnum["AMERICA_CRESTON"] = "America/Creston";
    TimezoneEnum["AMERICA_CUIABA"] = "America/Cuiaba";
    TimezoneEnum["AMERICA_CURACAO"] = "America/Curacao";
    TimezoneEnum["AMERICA_DANMARKSHAVN"] = "America/Danmarkshavn";
    TimezoneEnum["AMERICA_DAWSON"] = "America/Dawson";
    TimezoneEnum["AMERICA_DAWSON_CREEK"] = "America/Dawson_Creek";
    TimezoneEnum["AMERICA_DENVER"] = "America/Denver";
    TimezoneEnum["AMERICA_DETROIT"] = "America/Detroit";
    TimezoneEnum["AMERICA_DOMINICA"] = "America/Dominica";
    TimezoneEnum["AMERICA_EDMONTON"] = "America/Edmonton";
    TimezoneEnum["AMERICA_EIRUNEPE"] = "America/Eirunepe";
    TimezoneEnum["AMERICA_EL_SALVADOR"] = "America/El_Salvador";
    TimezoneEnum["AMERICA_ENSENADA"] = "America/Ensenada";
    TimezoneEnum["AMERICA_FORT_NELSON"] = "America/Fort_Nelson";
    TimezoneEnum["AMERICA_FORT_WAYNE"] = "America/Fort_Wayne";
    TimezoneEnum["AMERICA_FORTALEZA"] = "America/Fortaleza";
    TimezoneEnum["AMERICA_GLACE_BAY"] = "America/Glace_Bay";
    TimezoneEnum["AMERICA_GODTHAB"] = "America/Godthab";
    TimezoneEnum["AMERICA_GOOSE_BAY"] = "America/Goose_Bay";
    TimezoneEnum["AMERICA_GRAND_TURK"] = "America/Grand_Turk";
    TimezoneEnum["AMERICA_GRENADA"] = "America/Grenada";
    TimezoneEnum["AMERICA_GUADELOUPE"] = "America/Guadeloupe";
    TimezoneEnum["AMERICA_GUATEMALA"] = "America/Guatemala";
    TimezoneEnum["AMERICA_GUAYAQUIL"] = "America/Guayaquil";
    TimezoneEnum["AMERICA_GUYANA"] = "America/Guyana";
    TimezoneEnum["AMERICA_HALIFAX"] = "America/Halifax";
    TimezoneEnum["AMERICA_HAVANA"] = "America/Havana";
    TimezoneEnum["AMERICA_HERMOSILLO"] = "America/Hermosillo";
    TimezoneEnum["AMERICA_INDIANA_INDIANAPOLIS"] = "America/Indiana/Indianapolis";
    TimezoneEnum["AMERICA_INDIANA_KNOX"] = "America/Indiana/Knox";
    TimezoneEnum["AMERICA_INDIANA_MARENGO"] = "America/Indiana/Marengo";
    TimezoneEnum["AMERICA_INDIANA_PETERSBURG"] = "America/Indiana/Petersburg";
    TimezoneEnum["AMERICA_INDIANA_TELL_CITY"] = "America/Indiana/Tell_City";
    TimezoneEnum["AMERICA_INDIANA_VEVAY"] = "America/Indiana/Vevay";
    TimezoneEnum["AMERICA_INDIANA_VINCENNES"] = "America/Indiana/Vincennes";
    TimezoneEnum["AMERICA_INDIANA_WINAMAC"] = "America/Indiana/Winamac";
    TimezoneEnum["AMERICA_INDIANAPOLIS"] = "America/Indianapolis";
    TimezoneEnum["AMERICA_INUVIK"] = "America/Inuvik";
    TimezoneEnum["AMERICA_IQALUIT"] = "America/Iqaluit";
    TimezoneEnum["AMERICA_JAMAICA"] = "America/Jamaica";
    TimezoneEnum["AMERICA_JUJUY"] = "America/Jujuy";
    TimezoneEnum["AMERICA_JUNEAU"] = "America/Juneau";
    TimezoneEnum["AMERICA_KENTUCKY_LOUISVILLE"] = "America/Kentucky/Louisville";
    TimezoneEnum["AMERICA_KENTUCKY_MONTICELLO"] = "America/Kentucky/Monticello";
    TimezoneEnum["AMERICA_KNOX_IN"] = "America/Knox_IN";
    TimezoneEnum["AMERICA_KRALENDIJK"] = "America/Kralendijk";
    TimezoneEnum["AMERICA_LA_PAZ"] = "America/La_Paz";
    TimezoneEnum["AMERICA_LIMA"] = "America/Lima";
    TimezoneEnum["AMERICA_LOS_ANGELES"] = "America/Los_Angeles";
    TimezoneEnum["AMERICA_LOUISVILLE"] = "America/Louisville";
    TimezoneEnum["AMERICA_LOWER_PRINCES"] = "America/Lower_Princes";
    TimezoneEnum["AMERICA_MACEIO"] = "America/Maceio";
    TimezoneEnum["AMERICA_MANAGUA"] = "America/Managua";
    TimezoneEnum["AMERICA_MANAUS"] = "America/Manaus";
    TimezoneEnum["AMERICA_MARIGOT"] = "America/Marigot";
    TimezoneEnum["AMERICA_MARTINIQUE"] = "America/Martinique";
    TimezoneEnum["AMERICA_MATAMOROS"] = "America/Matamoros";
    TimezoneEnum["AMERICA_MAZATLAN"] = "America/Mazatlan";
    TimezoneEnum["AMERICA_MENDOZA"] = "America/Mendoza";
    TimezoneEnum["AMERICA_MENOMINEE"] = "America/Menominee";
    TimezoneEnum["AMERICA_MERIDA"] = "America/Merida";
    TimezoneEnum["AMERICA_METLAKATLA"] = "America/Metlakatla";
    TimezoneEnum["AMERICA_MEXICO_CITY"] = "America/Mexico_City";
    TimezoneEnum["AMERICA_MIQUELON"] = "America/Miquelon";
    TimezoneEnum["AMERICA_MONCTON"] = "America/Moncton";
    TimezoneEnum["AMERICA_MONTERREY"] = "America/Monterrey";
    TimezoneEnum["AMERICA_MONTEVIDEO"] = "America/Montevideo";
    TimezoneEnum["AMERICA_MONTREAL"] = "America/Montreal";
    TimezoneEnum["AMERICA_MONTSERRAT"] = "America/Montserrat";
    TimezoneEnum["AMERICA_NASSAU"] = "America/Nassau";
    TimezoneEnum["AMERICA_NEW_YORK"] = "America/New_York";
    TimezoneEnum["AMERICA_NIPIGON"] = "America/Nipigon";
    TimezoneEnum["AMERICA_NOME"] = "America/Nome";
    TimezoneEnum["AMERICA_NORONHA"] = "America/Noronha";
    TimezoneEnum["AMERICA_NORTH_DAKOTA_BEULAH"] = "America/North_Dakota/Beulah";
    TimezoneEnum["AMERICA_NORTH_DAKOTA_CENTER"] = "America/North_Dakota/Center";
    TimezoneEnum["AMERICA_NORTH_DAKOTA_NEW_SALEM"] = "America/North_Dakota/New_Salem";
    TimezoneEnum["AMERICA_OJINAGA"] = "America/Ojinaga";
    TimezoneEnum["AMERICA_PANAMA"] = "America/Panama";
    TimezoneEnum["AMERICA_PANGNIRTUNG"] = "America/Pangnirtung";
    TimezoneEnum["AMERICA_PARAMARIBO"] = "America/Paramaribo";
    TimezoneEnum["AMERICA_PHOENIX"] = "America/Phoenix";
    TimezoneEnum["AMERICA_PORT_AU_PRINCE"] = "America/Port-au-Prince";
    TimezoneEnum["AMERICA_PORT_OF_SPAIN"] = "America/Port_of_Spain";
    TimezoneEnum["AMERICA_PORTO_ACRE"] = "America/Porto_Acre";
    TimezoneEnum["AMERICA_PORTO_VELHO"] = "America/Porto_Velho";
    TimezoneEnum["AMERICA_PUERTO_RICO"] = "America/Puerto_Rico";
    TimezoneEnum["AMERICA_PUNTA_ARENAS"] = "America/Punta_Arenas";
    TimezoneEnum["AMERICA_RAINY_RIVER"] = "America/Rainy_River";
    TimezoneEnum["AMERICA_RANKIN_INLET"] = "America/Rankin_Inlet";
    TimezoneEnum["AMERICA_RECIFE"] = "America/Recife";
    TimezoneEnum["AMERICA_REGINA"] = "America/Regina";
    TimezoneEnum["AMERICA_RESOLUTE"] = "America/Resolute";
    TimezoneEnum["AMERICA_RIO_BRANCO"] = "America/Rio_Branco";
    TimezoneEnum["AMERICA_ROSARIO"] = "America/Rosario";
    TimezoneEnum["AMERICA_SANTA_ISABEL"] = "America/Santa_Isabel";
    TimezoneEnum["AMERICA_SANTAREM"] = "America/Santarem";
    TimezoneEnum["AMERICA_SANTIAGO"] = "America/Santiago";
    TimezoneEnum["AMERICA_SANTO_DOMINGO"] = "America/Santo_Domingo";
    TimezoneEnum["AMERICA_SAO_PAULO"] = "America/Sao_Paulo";
    TimezoneEnum["AMERICA_SCORESBYSUND"] = "America/Scoresbysund";
    TimezoneEnum["AMERICA_SHIPROCK"] = "America/Shiprock";
    TimezoneEnum["AMERICA_SITKA"] = "America/Sitka";
    TimezoneEnum["AMERICA_ST_BARTHELEMY"] = "America/St_Barthelemy";
    TimezoneEnum["AMERICA_ST_JOHNS"] = "America/St_Johns";
    TimezoneEnum["AMERICA_ST_KITTS"] = "America/St_Kitts";
    TimezoneEnum["AMERICA_ST_LUCIA"] = "America/St_Lucia";
    TimezoneEnum["AMERICA_ST_THOMAS"] = "America/St_Thomas";
    TimezoneEnum["AMERICA_ST_VINCENT"] = "America/St_Vincent";
    TimezoneEnum["AMERICA_SWIFT_CURRENT"] = "America/Swift_Current";
    TimezoneEnum["AMERICA_TEGUCIGALPA"] = "America/Tegucigalpa";
    TimezoneEnum["AMERICA_THULE"] = "America/Thule";
    TimezoneEnum["AMERICA_THUNDER_BAY"] = "America/Thunder_Bay";
    TimezoneEnum["AMERICA_TIJUANA"] = "America/Tijuana";
    TimezoneEnum["AMERICA_TORONTO"] = "America/Toronto";
    TimezoneEnum["AMERICA_TORTOLA"] = "America/Tortola";
    TimezoneEnum["AMERICA_VANCOUVER"] = "America/Vancouver";
    TimezoneEnum["AMERICA_VIRGIN"] = "America/Virgin";
    TimezoneEnum["AMERICA_WHITEHORSE"] = "America/Whitehorse";
    TimezoneEnum["AMERICA_WINNIPEG"] = "America/Winnipeg";
    TimezoneEnum["AMERICA_YAKUTAT"] = "America/Yakutat";
    TimezoneEnum["AMERICA_YELLOWKNIFE"] = "America/Yellowknife";
    TimezoneEnum["ANTARCTICA_CASEY"] = "Antarctica/Casey";
    TimezoneEnum["ANTARCTICA_DAVIS"] = "Antarctica/Davis";
    TimezoneEnum["ANTARCTICA_DUMONT_D_URVILLE"] = "Antarctica/DumontDUrville";
    TimezoneEnum["ANTARCTICA_MACQUARIE"] = "Antarctica/Macquarie";
    TimezoneEnum["ANTARCTICA_MAWSON"] = "Antarctica/Mawson";
    TimezoneEnum["ANTARCTICA_MC_MURDO"] = "Antarctica/McMurdo";
    TimezoneEnum["ANTARCTICA_PALMER"] = "Antarctica/Palmer";
    TimezoneEnum["ANTARCTICA_ROTHERA"] = "Antarctica/Rothera";
    TimezoneEnum["ANTARCTICA_SOUTH_POLE"] = "Antarctica/South_Pole";
    TimezoneEnum["ANTARCTICA_SYOWA"] = "Antarctica/Syowa";
    TimezoneEnum["ANTARCTICA_TROLL"] = "Antarctica/Troll";
    TimezoneEnum["ANTARCTICA_VOSTOK"] = "Antarctica/Vostok";
    TimezoneEnum["ARCTIC_LONGYEARBYEN"] = "Arctic/Longyearbyen";
    TimezoneEnum["ASIA_ADEN"] = "Asia/Aden";
    TimezoneEnum["ASIA_ALMATY"] = "Asia/Almaty";
    TimezoneEnum["ASIA_AMMAN"] = "Asia/Amman";
    TimezoneEnum["ASIA_ANADYR"] = "Asia/Anadyr";
    TimezoneEnum["ASIA_AQTAU"] = "Asia/Aqtau";
    TimezoneEnum["ASIA_AQTOBE"] = "Asia/Aqtobe";
    TimezoneEnum["ASIA_ASHGABAT"] = "Asia/Ashgabat";
    TimezoneEnum["ASIA_ASHKHABAD"] = "Asia/Ashkhabad";
    TimezoneEnum["ASIA_ATYRAU"] = "Asia/Atyrau";
    TimezoneEnum["ASIA_BAGHDAD"] = "Asia/Baghdad";
    TimezoneEnum["ASIA_BAHRAIN"] = "Asia/Bahrain";
    TimezoneEnum["ASIA_BAKU"] = "Asia/Baku";
    TimezoneEnum["ASIA_BANGKOK"] = "Asia/Bangkok";
    TimezoneEnum["ASIA_BARNAUL"] = "Asia/Barnaul";
    TimezoneEnum["ASIA_BEIRUT"] = "Asia/Beirut";
    TimezoneEnum["ASIA_BISHKEK"] = "Asia/Bishkek";
    TimezoneEnum["ASIA_BRUNEI"] = "Asia/Brunei";
    TimezoneEnum["ASIA_CALCUTTA"] = "Asia/Calcutta";
    TimezoneEnum["ASIA_CHITA"] = "Asia/Chita";
    TimezoneEnum["ASIA_CHOIBALSAN"] = "Asia/Choibalsan";
    TimezoneEnum["ASIA_CHONGQING"] = "Asia/Chongqing";
    TimezoneEnum["ASIA_CHUNGKING"] = "Asia/Chungking";
    TimezoneEnum["ASIA_COLOMBO"] = "Asia/Colombo";
    TimezoneEnum["ASIA_DACCA"] = "Asia/Dacca";
    TimezoneEnum["ASIA_DAMASCUS"] = "Asia/Damascus";
    TimezoneEnum["ASIA_DHAKA"] = "Asia/Dhaka";
    TimezoneEnum["ASIA_DILI"] = "Asia/Dili";
    TimezoneEnum["ASIA_DUBAI"] = "Asia/Dubai";
    TimezoneEnum["ASIA_DUSHANBE"] = "Asia/Dushanbe";
    TimezoneEnum["ASIA_FAMAGUSTA"] = "Asia/Famagusta";
    TimezoneEnum["ASIA_GAZA"] = "Asia/Gaza";
    TimezoneEnum["ASIA_HARBIN"] = "Asia/Harbin";
    TimezoneEnum["ASIA_HEBRON"] = "Asia/Hebron";
    TimezoneEnum["ASIA_HO_CHI_MINH"] = "Asia/Ho_Chi_Minh";
    TimezoneEnum["ASIA_HONG_KONG"] = "Asia/Hong_Kong";
    TimezoneEnum["ASIA_HOVD"] = "Asia/Hovd";
    TimezoneEnum["ASIA_IRKUTSK"] = "Asia/Irkutsk";
    TimezoneEnum["ASIA_ISTANBUL"] = "Asia/Istanbul";
    TimezoneEnum["ASIA_JAKARTA"] = "Asia/Jakarta";
    TimezoneEnum["ASIA_JAYAPURA"] = "Asia/Jayapura";
    TimezoneEnum["ASIA_JERUSALEM"] = "Asia/Jerusalem";
    TimezoneEnum["ASIA_KABUL"] = "Asia/Kabul";
    TimezoneEnum["ASIA_KAMCHATKA"] = "Asia/Kamchatka";
    TimezoneEnum["ASIA_KARACHI"] = "Asia/Karachi";
    TimezoneEnum["ASIA_KASHGAR"] = "Asia/Kashgar";
    TimezoneEnum["ASIA_KATHMANDU"] = "Asia/Kathmandu";
    TimezoneEnum["ASIA_KATMANDU"] = "Asia/Katmandu";
    TimezoneEnum["ASIA_KHANDYGA"] = "Asia/Khandyga";
    TimezoneEnum["ASIA_KOLKATA"] = "Asia/Kolkata";
    TimezoneEnum["ASIA_KRASNOYARSK"] = "Asia/Krasnoyarsk";
    TimezoneEnum["ASIA_KUALA_LUMPUR"] = "Asia/Kuala_Lumpur";
    TimezoneEnum["ASIA_KUCHING"] = "Asia/Kuching";
    TimezoneEnum["ASIA_KUWAIT"] = "Asia/Kuwait";
    TimezoneEnum["ASIA_MACAO"] = "Asia/Macao";
    TimezoneEnum["ASIA_MACAU"] = "Asia/Macau";
    TimezoneEnum["ASIA_MAGADAN"] = "Asia/Magadan";
    TimezoneEnum["ASIA_MAKASSAR"] = "Asia/Makassar";
    TimezoneEnum["ASIA_MANILA"] = "Asia/Manila";
    TimezoneEnum["ASIA_MUSCAT"] = "Asia/Muscat";
    TimezoneEnum["ASIA_NICOSIA"] = "Asia/Nicosia";
    TimezoneEnum["ASIA_NOVOKUZNETSK"] = "Asia/Novokuznetsk";
    TimezoneEnum["ASIA_NOVOSIBIRSK"] = "Asia/Novosibirsk";
    TimezoneEnum["ASIA_OMSK"] = "Asia/Omsk";
    TimezoneEnum["ASIA_ORAL"] = "Asia/Oral";
    TimezoneEnum["ASIA_PHNOM_PENH"] = "Asia/Phnom_Penh";
    TimezoneEnum["ASIA_PONTIANAK"] = "Asia/Pontianak";
    TimezoneEnum["ASIA_PYONGYANG"] = "Asia/Pyongyang";
    TimezoneEnum["ASIA_QATAR"] = "Asia/Qatar";
    TimezoneEnum["ASIA_QOSTANAY"] = "Asia/Qostanay";
    TimezoneEnum["ASIA_QYZYLORDA"] = "Asia/Qyzylorda";
    TimezoneEnum["ASIA_RANGOON"] = "Asia/Rangoon";
    TimezoneEnum["ASIA_RIYADH"] = "Asia/Riyadh";
    TimezoneEnum["ASIA_SAIGON"] = "Asia/Saigon";
    TimezoneEnum["ASIA_SAKHALIN"] = "Asia/Sakhalin";
    TimezoneEnum["ASIA_SAMARKAND"] = "Asia/Samarkand";
    TimezoneEnum["ASIA_SEOUL"] = "Asia/Seoul";
    TimezoneEnum["ASIA_SHANGHAI"] = "Asia/Shanghai";
    TimezoneEnum["ASIA_SINGAPORE"] = "Asia/Singapore";
    TimezoneEnum["ASIA_SREDNEKOLYMSK"] = "Asia/Srednekolymsk";
    TimezoneEnum["ASIA_TAIPEI"] = "Asia/Taipei";
    TimezoneEnum["ASIA_TASHKENT"] = "Asia/Tashkent";
    TimezoneEnum["ASIA_TBILISI"] = "Asia/Tbilisi";
    TimezoneEnum["ASIA_TEHRAN"] = "Asia/Tehran";
    TimezoneEnum["ASIA_TEL_AVIV"] = "Asia/Tel_Aviv";
    TimezoneEnum["ASIA_THIMBU"] = "Asia/Thimbu";
    TimezoneEnum["ASIA_THIMPHU"] = "Asia/Thimphu";
    TimezoneEnum["ASIA_TOKYO"] = "Asia/Tokyo";
    TimezoneEnum["ASIA_TOMSK"] = "Asia/Tomsk";
    TimezoneEnum["ASIA_UJUNG_PANDANG"] = "Asia/Ujung_Pandang";
    TimezoneEnum["ASIA_ULAANBAATAR"] = "Asia/Ulaanbaatar";
    TimezoneEnum["ASIA_ULAN_BATOR"] = "Asia/Ulan_Bator";
    TimezoneEnum["ASIA_URUMQI"] = "Asia/Urumqi";
    TimezoneEnum["ASIA_UST_NERA"] = "Asia/Ust-Nera";
    TimezoneEnum["ASIA_VIENTIANE"] = "Asia/Vientiane";
    TimezoneEnum["ASIA_VLADIVOSTOK"] = "Asia/Vladivostok";
    TimezoneEnum["ASIA_YAKUTSK"] = "Asia/Yakutsk";
    TimezoneEnum["ASIA_YANGON"] = "Asia/Yangon";
    TimezoneEnum["ASIA_YEKATERINBURG"] = "Asia/Yekaterinburg";
    TimezoneEnum["ASIA_YEREVAN"] = "Asia/Yerevan";
    TimezoneEnum["ATLANTIC_AZORES"] = "Atlantic/Azores";
    TimezoneEnum["ATLANTIC_BERMUDA"] = "Atlantic/Bermuda";
    TimezoneEnum["ATLANTIC_CANARY"] = "Atlantic/Canary";
    TimezoneEnum["ATLANTIC_CAPE_VERDE"] = "Atlantic/Cape_Verde";
    TimezoneEnum["ATLANTIC_FAEROE"] = "Atlantic/Faeroe";
    TimezoneEnum["ATLANTIC_FAROE"] = "Atlantic/Faroe";
    TimezoneEnum["ATLANTIC_JAN_MAYEN"] = "Atlantic/Jan_Mayen";
    TimezoneEnum["ATLANTIC_MADEIRA"] = "Atlantic/Madeira";
    TimezoneEnum["ATLANTIC_REYKJAVIK"] = "Atlantic/Reykjavik";
    TimezoneEnum["ATLANTIC_SOUTH_GEORGIA"] = "Atlantic/South_Georgia";
    TimezoneEnum["ATLANTIC_ST_HELENA"] = "Atlantic/St_Helena";
    TimezoneEnum["ATLANTIC_STANLEY"] = "Atlantic/Stanley";
    TimezoneEnum["AUSTRALIA_ACT"] = "Australia/ACT";
    TimezoneEnum["AUSTRALIA_ADELAIDE"] = "Australia/Adelaide";
    TimezoneEnum["AUSTRALIA_BRISBANE"] = "Australia/Brisbane";
    TimezoneEnum["AUSTRALIA_BROKEN_HILL"] = "Australia/Broken_Hill";
    TimezoneEnum["AUSTRALIA_CANBERRA"] = "Australia/Canberra";
    TimezoneEnum["AUSTRALIA_CURRIE"] = "Australia/Currie";
    TimezoneEnum["AUSTRALIA_DARWIN"] = "Australia/Darwin";
    TimezoneEnum["AUSTRALIA_EUCLA"] = "Australia/Eucla";
    TimezoneEnum["AUSTRALIA_HOBART"] = "Australia/Hobart";
    TimezoneEnum["AUSTRALIA_LHI"] = "Australia/LHI";
    TimezoneEnum["AUSTRALIA_LINDEMAN"] = "Australia/Lindeman";
    TimezoneEnum["AUSTRALIA_LORD_HOWE"] = "Australia/Lord_Howe";
    TimezoneEnum["AUSTRALIA_MELBOURNE"] = "Australia/Melbourne";
    TimezoneEnum["AUSTRALIA_NORTH"] = "Australia/North";
    TimezoneEnum["AUSTRALIA_NSW"] = "Australia/NSW";
    TimezoneEnum["AUSTRALIA_PERTH"] = "Australia/Perth";
    TimezoneEnum["AUSTRALIA_QUEENSLAND"] = "Australia/Queensland";
    TimezoneEnum["AUSTRALIA_SOUTH"] = "Australia/South";
    TimezoneEnum["AUSTRALIA_SYDNEY"] = "Australia/Sydney";
    TimezoneEnum["AUSTRALIA_TASMANIA"] = "Australia/Tasmania";
    TimezoneEnum["AUSTRALIA_VICTORIA"] = "Australia/Victoria";
    TimezoneEnum["AUSTRALIA_WEST"] = "Australia/West";
    TimezoneEnum["AUSTRALIA_YANCOWINNA"] = "Australia/Yancowinna";
    TimezoneEnum["BRAZIL_ACRE"] = "Brazil/Acre";
    TimezoneEnum["BRAZIL_DE_NORONHA"] = "Brazil/DeNoronha";
    TimezoneEnum["BRAZIL_EAST"] = "Brazil/East";
    TimezoneEnum["BRAZIL_WEST"] = "Brazil/West";
    TimezoneEnum["CANADA_ATLANTIC"] = "Canada/Atlantic";
    TimezoneEnum["CANADA_CENTRAL"] = "Canada/Central";
    TimezoneEnum["CANADA_EASTERN"] = "Canada/Eastern";
    TimezoneEnum["CANADA_MOUNTAIN"] = "Canada/Mountain";
    TimezoneEnum["CANADA_NEWFOUNDLAND"] = "Canada/Newfoundland";
    TimezoneEnum["CANADA_PACIFIC"] = "Canada/Pacific";
    TimezoneEnum["CANADA_SASKATCHEWAN"] = "Canada/Saskatchewan";
    TimezoneEnum["CANADA_YUKON"] = "Canada/Yukon";
    TimezoneEnum["CET"] = "CET";
    TimezoneEnum["CHILE_CONTINENTAL"] = "Chile/Continental";
    TimezoneEnum["CHILE_EASTER_ISLAND"] = "Chile/EasterIsland";
    TimezoneEnum["CST6_CDT"] = "CST6CDT";
    TimezoneEnum["CUBA"] = "Cuba";
    TimezoneEnum["EET"] = "EET";
    TimezoneEnum["EGYPT"] = "Egypt";
    TimezoneEnum["EIRE"] = "Eire";
    TimezoneEnum["EST"] = "EST";
    TimezoneEnum["EST5_EDT"] = "EST5EDT";
    TimezoneEnum["ETC_GMT"] = "Etc/GMT";
    TimezoneEnum["ETC_GMT_0"] = "Etc/GMT+0";
    TimezoneEnum["ETC_GMT_MINUS_0"] = "Etc/GMT-0";
    TimezoneEnum["ETC_GMT_MINUS_1"] = "Etc/GMT-1";
    TimezoneEnum["ETC_GMT_MINUS_10"] = "Etc/GMT-10";
    TimezoneEnum["ETC_GMT_MINUS_11"] = "Etc/GMT-11";
    TimezoneEnum["ETC_GMT_MINUS_12"] = "Etc/GMT-12";
    TimezoneEnum["ETC_GMT_MINUS_13"] = "Etc/GMT-13";
    TimezoneEnum["ETC_GMT_MINUS_14"] = "Etc/GMT-14";
    TimezoneEnum["ETC_GMT_MINUS_2"] = "Etc/GMT-2";
    TimezoneEnum["ETC_GMT_MINUS_3"] = "Etc/GMT-3";
    TimezoneEnum["ETC_GMT_MINUS_4"] = "Etc/GMT-4";
    TimezoneEnum["ETC_GMT_MINUS_5"] = "Etc/GMT-5";
    TimezoneEnum["ETC_GMT_MINUS_6"] = "Etc/GMT-6";
    TimezoneEnum["ETC_GMT_MINUS_7"] = "Etc/GMT-7";
    TimezoneEnum["ETC_GMT_MINUS_8"] = "Etc/GMT-8";
    TimezoneEnum["ETC_GMT_MINUS_9"] = "Etc/GMT-9";
    TimezoneEnum["ETC_GMT_PLUS_1"] = "Etc/GMT+1";
    TimezoneEnum["ETC_GMT_PLUS_10"] = "Etc/GMT+10";
    TimezoneEnum["ETC_GMT_PLUS_11"] = "Etc/GMT+11";
    TimezoneEnum["ETC_GMT_PLUS_12"] = "Etc/GMT+12";
    TimezoneEnum["ETC_GMT_PLUS_2"] = "Etc/GMT+2";
    TimezoneEnum["ETC_GMT_PLUS_3"] = "Etc/GMT+3";
    TimezoneEnum["ETC_GMT_PLUS_4"] = "Etc/GMT+4";
    TimezoneEnum["ETC_GMT_PLUS_5"] = "Etc/GMT+5";
    TimezoneEnum["ETC_GMT_PLUS_6"] = "Etc/GMT+6";
    TimezoneEnum["ETC_GMT_PLUS_7"] = "Etc/GMT+7";
    TimezoneEnum["ETC_GMT_PLUS_8"] = "Etc/GMT+8";
    TimezoneEnum["ETC_GMT_PLUS_9"] = "Etc/GMT+9";
    TimezoneEnum["ETC_GMT0"] = "Etc/GMT0";
    TimezoneEnum["ETC_GREENWICH"] = "Etc/Greenwich";
    TimezoneEnum["ETC_UCT"] = "Etc/UCT";
    TimezoneEnum["ETC_UNIVERSAL"] = "Etc/Universal";
    TimezoneEnum["ETC_UTC"] = "Etc/UTC";
    TimezoneEnum["ETC_ZULU"] = "Etc/Zulu";
    TimezoneEnum["EUROPE_AMSTERDAM"] = "Europe/Amsterdam";
    TimezoneEnum["EUROPE_ANDORRA"] = "Europe/Andorra";
    TimezoneEnum["EUROPE_ASTRAKHAN"] = "Europe/Astrakhan";
    TimezoneEnum["EUROPE_ATHENS"] = "Europe/Athens";
    TimezoneEnum["EUROPE_BELFAST"] = "Europe/Belfast";
    TimezoneEnum["EUROPE_BELGRADE"] = "Europe/Belgrade";
    TimezoneEnum["EUROPE_BERLIN"] = "Europe/Berlin";
    TimezoneEnum["EUROPE_BRATISLAVA"] = "Europe/Bratislava";
    TimezoneEnum["EUROPE_BRUSSELS"] = "Europe/Brussels";
    TimezoneEnum["EUROPE_BUCHAREST"] = "Europe/Bucharest";
    TimezoneEnum["EUROPE_BUDAPEST"] = "Europe/Budapest";
    TimezoneEnum["EUROPE_BUSINGEN"] = "Europe/Busingen";
    TimezoneEnum["EUROPE_CHISINAU"] = "Europe/Chisinau";
    TimezoneEnum["EUROPE_COPENHAGEN"] = "Europe/Copenhagen";
    TimezoneEnum["EUROPE_DUBLIN"] = "Europe/Dublin";
    TimezoneEnum["EUROPE_GIBRALTAR"] = "Europe/Gibraltar";
    TimezoneEnum["EUROPE_GUERNSEY"] = "Europe/Guernsey";
    TimezoneEnum["EUROPE_HELSINKI"] = "Europe/Helsinki";
    TimezoneEnum["EUROPE_ISLE_OF_MAN"] = "Europe/Isle_of_Man";
    TimezoneEnum["EUROPE_ISTANBUL"] = "Europe/Istanbul";
    TimezoneEnum["EUROPE_JERSEY"] = "Europe/Jersey";
    TimezoneEnum["EUROPE_KALININGRAD"] = "Europe/Kaliningrad";
    TimezoneEnum["EUROPE_KIEV"] = "Europe/Kiev";
    TimezoneEnum["EUROPE_KIROV"] = "Europe/Kirov";
    TimezoneEnum["EUROPE_LISBON"] = "Europe/Lisbon";
    TimezoneEnum["EUROPE_LJUBLJANA"] = "Europe/Ljubljana";
    TimezoneEnum["EUROPE_LONDON"] = "Europe/London";
    TimezoneEnum["EUROPE_LUXEMBOURG"] = "Europe/Luxembourg";
    TimezoneEnum["EUROPE_MADRID"] = "Europe/Madrid";
    TimezoneEnum["EUROPE_MALTA"] = "Europe/Malta";
    TimezoneEnum["EUROPE_MARIEHAMN"] = "Europe/Mariehamn";
    TimezoneEnum["EUROPE_MINSK"] = "Europe/Minsk";
    TimezoneEnum["EUROPE_MONACO"] = "Europe/Monaco";
    TimezoneEnum["EUROPE_MOSCOW"] = "Europe/Moscow";
    TimezoneEnum["EUROPE_NICOSIA"] = "Europe/Nicosia";
    TimezoneEnum["EUROPE_OSLO"] = "Europe/Oslo";
    TimezoneEnum["EUROPE_PARIS"] = "Europe/Paris";
    TimezoneEnum["EUROPE_PODGORICA"] = "Europe/Podgorica";
    TimezoneEnum["EUROPE_PRAGUE"] = "Europe/Prague";
    TimezoneEnum["EUROPE_RIGA"] = "Europe/Riga";
    TimezoneEnum["EUROPE_ROME"] = "Europe/Rome";
    TimezoneEnum["EUROPE_SAMARA"] = "Europe/Samara";
    TimezoneEnum["EUROPE_SAN_MARINO"] = "Europe/San_Marino";
    TimezoneEnum["EUROPE_SARAJEVO"] = "Europe/Sarajevo";
    TimezoneEnum["EUROPE_SARATOV"] = "Europe/Saratov";
    TimezoneEnum["EUROPE_SIMFEROPOL"] = "Europe/Simferopol";
    TimezoneEnum["EUROPE_SKOPJE"] = "Europe/Skopje";
    TimezoneEnum["EUROPE_SOFIA"] = "Europe/Sofia";
    TimezoneEnum["EUROPE_STOCKHOLM"] = "Europe/Stockholm";
    TimezoneEnum["EUROPE_TALLINN"] = "Europe/Tallinn";
    TimezoneEnum["EUROPE_TIRANE"] = "Europe/Tirane";
    TimezoneEnum["EUROPE_TIRASPOL"] = "Europe/Tiraspol";
    TimezoneEnum["EUROPE_ULYANOVSK"] = "Europe/Ulyanovsk";
    TimezoneEnum["EUROPE_UZHGOROD"] = "Europe/Uzhgorod";
    TimezoneEnum["EUROPE_VADUZ"] = "Europe/Vaduz";
    TimezoneEnum["EUROPE_VATICAN"] = "Europe/Vatican";
    TimezoneEnum["EUROPE_VIENNA"] = "Europe/Vienna";
    TimezoneEnum["EUROPE_VILNIUS"] = "Europe/Vilnius";
    TimezoneEnum["EUROPE_VOLGOGRAD"] = "Europe/Volgograd";
    TimezoneEnum["EUROPE_WARSAW"] = "Europe/Warsaw";
    TimezoneEnum["EUROPE_ZAGREB"] = "Europe/Zagreb";
    TimezoneEnum["EUROPE_ZAPOROZHYE"] = "Europe/Zaporozhye";
    TimezoneEnum["EUROPE_ZURICH"] = "Europe/Zurich";
    TimezoneEnum["FACTORY"] = "Factory";
    TimezoneEnum["GB"] = "GB";
    TimezoneEnum["GB_EIRE"] = "GB-Eire";
    TimezoneEnum["GMT"] = "GMT";
    TimezoneEnum["GMT_MINUS_0"] = "GMT-0";
    TimezoneEnum["GMT_PLUS_0"] = "GMT+0";
    TimezoneEnum["GMT0"] = "GMT0";
    TimezoneEnum["GREENWICH"] = "Greenwich";
    TimezoneEnum["HONGKONG"] = "Hongkong";
    TimezoneEnum["HST"] = "HST";
    TimezoneEnum["ICELAND"] = "Iceland";
    TimezoneEnum["INDIAN_ANTANANARIVO"] = "Indian/Antananarivo";
    TimezoneEnum["INDIAN_CHAGOS"] = "Indian/Chagos";
    TimezoneEnum["INDIAN_CHRISTMAS"] = "Indian/Christmas";
    TimezoneEnum["INDIAN_COCOS"] = "Indian/Cocos";
    TimezoneEnum["INDIAN_COMORO"] = "Indian/Comoro";
    TimezoneEnum["INDIAN_KERGUELEN"] = "Indian/Kerguelen";
    TimezoneEnum["INDIAN_MAHE"] = "Indian/Mahe";
    TimezoneEnum["INDIAN_MALDIVES"] = "Indian/Maldives";
    TimezoneEnum["INDIAN_MAURITIUS"] = "Indian/Mauritius";
    TimezoneEnum["INDIAN_MAYOTTE"] = "Indian/Mayotte";
    TimezoneEnum["INDIAN_REUNION"] = "Indian/Reunion";
    TimezoneEnum["IRAN"] = "Iran";
    TimezoneEnum["ISRAEL"] = "Israel";
    TimezoneEnum["JAMAICA"] = "Jamaica";
    TimezoneEnum["JAPAN"] = "Japan";
    TimezoneEnum["KWAJALEIN"] = "Kwajalein";
    TimezoneEnum["LIBYA"] = "Libya";
    TimezoneEnum["MET"] = "MET";
    TimezoneEnum["MEXICO_BAJA_NORTE"] = "Mexico/BajaNorte";
    TimezoneEnum["MEXICO_BAJA_SUR"] = "Mexico/BajaSur";
    TimezoneEnum["MEXICO_GENERAL"] = "Mexico/General";
    TimezoneEnum["MST"] = "MST";
    TimezoneEnum["MST7_MDT"] = "MST7MDT";
    TimezoneEnum["NAVAJO"] = "Navajo";
    TimezoneEnum["NZ"] = "NZ";
    TimezoneEnum["NZ_CHAT"] = "NZ-CHAT";
    TimezoneEnum["PACIFIC_APIA"] = "Pacific/Apia";
    TimezoneEnum["PACIFIC_AUCKLAND"] = "Pacific/Auckland";
    TimezoneEnum["PACIFIC_BOUGAINVILLE"] = "Pacific/Bougainville";
    TimezoneEnum["PACIFIC_CHATHAM"] = "Pacific/Chatham";
    TimezoneEnum["PACIFIC_CHUUK"] = "Pacific/Chuuk";
    TimezoneEnum["PACIFIC_EASTER"] = "Pacific/Easter";
    TimezoneEnum["PACIFIC_EFATE"] = "Pacific/Efate";
    TimezoneEnum["PACIFIC_ENDERBURY"] = "Pacific/Enderbury";
    TimezoneEnum["PACIFIC_FAKAOFO"] = "Pacific/Fakaofo";
    TimezoneEnum["PACIFIC_FIJI"] = "Pacific/Fiji";
    TimezoneEnum["PACIFIC_FUNAFUTI"] = "Pacific/Funafuti";
    TimezoneEnum["PACIFIC_GALAPAGOS"] = "Pacific/Galapagos";
    TimezoneEnum["PACIFIC_GAMBIER"] = "Pacific/Gambier";
    TimezoneEnum["PACIFIC_GUADALCANAL"] = "Pacific/Guadalcanal";
    TimezoneEnum["PACIFIC_GUAM"] = "Pacific/Guam";
    TimezoneEnum["PACIFIC_HONOLULU"] = "Pacific/Honolulu";
    TimezoneEnum["PACIFIC_JOHNSTON"] = "Pacific/Johnston";
    TimezoneEnum["PACIFIC_KIRITIMATI"] = "Pacific/Kiritimati";
    TimezoneEnum["PACIFIC_KOSRAE"] = "Pacific/Kosrae";
    TimezoneEnum["PACIFIC_KWAJALEIN"] = "Pacific/Kwajalein";
    TimezoneEnum["PACIFIC_MAJURO"] = "Pacific/Majuro";
    TimezoneEnum["PACIFIC_MARQUESAS"] = "Pacific/Marquesas";
    TimezoneEnum["PACIFIC_MIDWAY"] = "Pacific/Midway";
    TimezoneEnum["PACIFIC_NAURU"] = "Pacific/Nauru";
    TimezoneEnum["PACIFIC_NIUE"] = "Pacific/Niue";
    TimezoneEnum["PACIFIC_NORFOLK"] = "Pacific/Norfolk";
    TimezoneEnum["PACIFIC_NOUMEA"] = "Pacific/Noumea";
    TimezoneEnum["PACIFIC_PAGO_PAGO"] = "Pacific/Pago_Pago";
    TimezoneEnum["PACIFIC_PALAU"] = "Pacific/Palau";
    TimezoneEnum["PACIFIC_PITCAIRN"] = "Pacific/Pitcairn";
    TimezoneEnum["PACIFIC_POHNPEI"] = "Pacific/Pohnpei";
    TimezoneEnum["PACIFIC_PONAPE"] = "Pacific/Ponape";
    TimezoneEnum["PACIFIC_PORT_MORESBY"] = "Pacific/Port_Moresby";
    TimezoneEnum["PACIFIC_RAROTONGA"] = "Pacific/Rarotonga";
    TimezoneEnum["PACIFIC_SAIPAN"] = "Pacific/Saipan";
    TimezoneEnum["PACIFIC_SAMOA"] = "Pacific/Samoa";
    TimezoneEnum["PACIFIC_TAHITI"] = "Pacific/Tahiti";
    TimezoneEnum["PACIFIC_TARAWA"] = "Pacific/Tarawa";
    TimezoneEnum["PACIFIC_TONGATAPU"] = "Pacific/Tongatapu";
    TimezoneEnum["PACIFIC_TRUK"] = "Pacific/Truk";
    TimezoneEnum["PACIFIC_WAKE"] = "Pacific/Wake";
    TimezoneEnum["PACIFIC_WALLIS"] = "Pacific/Wallis";
    TimezoneEnum["PACIFIC_YAP"] = "Pacific/Yap";
    TimezoneEnum["POLAND"] = "Poland";
    TimezoneEnum["PORTUGAL"] = "Portugal";
    TimezoneEnum["PRC"] = "PRC";
    TimezoneEnum["PST8_PDT"] = "PST8PDT";
    TimezoneEnum["ROC"] = "ROC";
    TimezoneEnum["ROK"] = "ROK";
    TimezoneEnum["SINGAPORE"] = "Singapore";
    TimezoneEnum["TURKEY"] = "Turkey";
    TimezoneEnum["UCT"] = "UCT";
    TimezoneEnum["UNIVERSAL"] = "Universal";
    TimezoneEnum["US_ALASKA"] = "US/Alaska";
    TimezoneEnum["US_ALEUTIAN"] = "US/Aleutian";
    TimezoneEnum["US_ARIZONA"] = "US/Arizona";
    TimezoneEnum["US_CENTRAL"] = "US/Central";
    TimezoneEnum["US_EAST_INDIANA"] = "US/East-Indiana";
    TimezoneEnum["US_EASTERN"] = "US/Eastern";
    TimezoneEnum["US_HAWAII"] = "US/Hawaii";
    TimezoneEnum["US_INDIANA_STARKE"] = "US/Indiana-Starke";
    TimezoneEnum["US_MICHIGAN"] = "US/Michigan";
    TimezoneEnum["US_MOUNTAIN"] = "US/Mountain";
    TimezoneEnum["US_PACIFIC"] = "US/Pacific";
    TimezoneEnum["US_PACIFIC_NEW"] = "US/Pacific-New";
    TimezoneEnum["US_SAMOA"] = "US/Samoa";
    TimezoneEnum["UTC"] = "UTC";
    TimezoneEnum["W_SU"] = "W-SU";
    TimezoneEnum["WET"] = "WET";
    TimezoneEnum["ZULU"] = "Zulu";
})(TimezoneEnum || (TimezoneEnum = {}));
