export var FieldOperatorEnum;
(function (FieldOperatorEnum) {
    FieldOperatorEnum["ALL_IN"] = "ALL_IN";
    FieldOperatorEnum["ANY_IN"] = "ANY_IN";
    FieldOperatorEnum["BETWEEN"] = "BETWEEN";
    FieldOperatorEnum["EQUAL"] = "EQUAL";
    FieldOperatorEnum["IN"] = "IN";
    FieldOperatorEnum["IS_DEFINED"] = "IS_DEFINED";
    FieldOperatorEnum["LARGER"] = "LARGER";
    FieldOperatorEnum["LARGER_EQUAL"] = "LARGER_EQUAL";
    FieldOperatorEnum["LIKE"] = "LIKE";
    FieldOperatorEnum["NOT_BETWEEN"] = "NOT_BETWEEN";
    FieldOperatorEnum["NOT_EQUAL"] = "NOT_EQUAL";
    FieldOperatorEnum["NOT_IN"] = "NOT_IN";
    FieldOperatorEnum["NOT_LIKE"] = "NOT_LIKE";
    FieldOperatorEnum["SMALLER"] = "SMALLER";
    FieldOperatorEnum["SMALLER_EQUAL"] = "SMALLER_EQUAL";
})(FieldOperatorEnum || (FieldOperatorEnum = {}));
export var FieldLogicalOperatorEnum;
(function (FieldLogicalOperatorEnum) {
    FieldLogicalOperatorEnum["AND"] = "AND";
    FieldLogicalOperatorEnum["OR"] = "OR";
})(FieldLogicalOperatorEnum || (FieldLogicalOperatorEnum = {}));
