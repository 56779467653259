export var NovuComponentEnum;
(function (NovuComponentEnum) {
    NovuComponentEnum[NovuComponentEnum["WEB"] = 0] = "WEB";
    NovuComponentEnum[NovuComponentEnum["API"] = 1] = "API";
    NovuComponentEnum[NovuComponentEnum["WIDGET"] = 2] = "WIDGET";
    NovuComponentEnum[NovuComponentEnum["WS"] = 3] = "WS";
})(NovuComponentEnum || (NovuComponentEnum = {}));
export function getContextPath(component) {
    let contextPath = '';
    const env = typeof process !== 'undefined' && (process === null || process === void 0 ? void 0 : process.env) ? process === null || process === void 0 ? void 0 : process.env : window._env_;
    if (!env) {
        return contextPath;
    }
    if (env.GLOBAL_CONTEXT_PATH) {
        contextPath += env.GLOBAL_CONTEXT_PATH + '/';
    }
    switch (component) {
        case NovuComponentEnum.API:
            if (env.API_CONTEXT_PATH) {
                contextPath += env.API_CONTEXT_PATH + '/';
            }
            break;
        case NovuComponentEnum.WEB:
            if (env.FRONT_BASE_CONTEXT_PATH) {
                contextPath += env.FRONT_BASE_CONTEXT_PATH + '/';
            }
            break;
        case NovuComponentEnum.WIDGET:
            if (env.WIDGET_CONTEXT_PATH) {
                contextPath += env.WIDGET_CONTEXT_PATH + '/';
            }
            break;
        case NovuComponentEnum.WS:
            if (env.WS_CONTEXT_PATH) {
                contextPath += env.WS_CONTEXT_PATH + '/';
            }
            break;
    }
    return contextPath;
}
